<template>
    <div class="map">
        <template>
            <component
                :is="mapComponent"
                :zoom="zoom"
                :center="center"
                :url="url"
                :attribution="attribution"
                :marker="computedMarker"
                @click-on-marker="clickOnMarker"
            />
        </template>
    </div>
</template>
<script>
export default {
    name: 'CSMap',
    props: {
        zoom: {
            type: Number,
            default: 0
        },
        center: {
            type: Array,
            default: () => []
        },
        url: {
            type: String,
            default: ''
        },
        attribution: {
            type: String,
            default: ''
        },
        marker: {
            type: Array,
            default: () => []
        }
    },
    components: {
        MapEnabled: () => import('./MapEnabled.vue'),
        MapDisabled: () => import('./MapDisabled.vue')
    },
    computed: {
        mapComponent() {
            if (typeof window !== 'undefined') return 'MapEnabled'
            else return 'MapDisabled'
        },
        computedMarker() {
            if (!this.marker || !Array.isArray(this.marker) || !this.marker?.length) return [this.center, this.center];
  
            return this.marker
        }
    },
    methods: {
        clickOnMarker(blockId) {
            this.$emit('click-on-marker', blockId)
        }
    },
    mounted() {
        console.log(this.center, this.zoom, this.url, this.attribution, this.marker)
    },
}
</script>
<style lang="scss" scoped></style>
